@import url('https://fonts.googleapis.com/css?family=Montserrat&display=swap');

* {
  font-family: 'Montserrat';
  margin: 0;
}

p {
  margin-top: 40px;
  font-weight: bolder;
  background-color: #fff;
  color: #000;
  padding: 15px 30px;
  transition: all .5s ease;
}

p:hover {
  background-color: #00000000;
  color: #fff;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #191919;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
